import React, { useEffect, useState } from 'react'
import { graphql, PageProps } from 'gatsby'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import { GatsbyImage, getImage, ImageDataLike, StaticImage } from 'gatsby-plugin-image'
import { navigate } from 'gatsby'

// Load Page Contents
import contentEn from '../../content/pages/home.en.yaml'
import contentId from '../../content/pages/home.id.yaml'
import SEO from '../components/seo'
import moment, { locale } from 'moment'

// Props Type
interface IndexPageProps extends PageProps {
  data: {
    figureHero: ImageDataLike
    figureOmnichannel: ImageDataLike
    figureJascloud: ImageDataLike
    figureSakti: ImageDataLike
    figureJasmeet: ImageDataLike
    figureJasgrid: ImageDataLike
    figureCloudCommunication: PublicUrlType
    figureUnifiedCommunication: PublicUrlType
    figureSmartCity: PublicUrlType
    clientsCompany: {
      nodes: [
        {
          publicURL: string
        }
      ]
    }
    clientsGovernment: {
      nodes: [
        {
          publicURL: string
        }
      ]
    }
    allNews: {
      edges: [
        {
          node: {
            id: string
            title: string
            updated_at: string
            created_at: string
            remoteImage: ImageDataLike
            languages: [
              {
                locale: string
                column_name: string
                value: string
                desc: string
              }
            ]
          }
        }
      ]
    }
  }
}

// Index Page
const IndexPage = (props: IndexPageProps): JSX.Element => {
  const { t, i18n } = useTranslation()
  const [content, setContent] = useState(contentEn)
  const {
    figureHero,
    figureOmnichannel,
    figureJascloud,
    figureSakti,
    figureJasmeet,
    figureJasgrid,
    figureCloudCommunication,
    figureUnifiedCommunication,
    figureSmartCity,
    clientsCompany,
    clientsGovernment,
    allNews,
  } = props.data

  const heroImg = getImage(figureHero)
  const OmnichannelImg = getImage(figureOmnichannel)
  const JascloudImg = getImage(figureJascloud)
  const SaktiImg = getImage(figureSakti)
  const JasmeetImg = getImage(figureJasmeet)
  const JasgridImg = getImage(figureJasgrid)

  useEffect(() => {
    switch (i18n.language) {
      case 'id':
        setContent(contentId)
        break
      case 'en':
        setContent(contentEn)
        break
      default:
        setContent(contentEn)
        break
    }
  }, [i18n.language])

  const generateSlug = (id: string, title: string, date: string) => {
    const pathName = title
      .toLowerCase()
      .replace(/[^\w ]+/g, ``)
      .replace(/ +/g, `-`)
    const postDate = moment(date)

    return `/investor/news/${postDate.year()}/${1 + postDate.month()}/${postDate.date()}/${
      id.split(`-`)[0]
    }-${pathName}`
  }

  return (
    <>
      <SEO title={content.title} description={content.description} lang={i18n.language}></SEO>

      {/* Start Hero Section */}
      <section className="uk-light">
        <div
          className="uk-background-norepeat tm-hero-background uk-flex uk-flex-middle"
          uk-img=""
          // style={{ minHeight: 'calc(100vh)' }}
        >
          <div className="uk-width-1-1">
            <div className="uk-container">
              <div style={{ position: 'relative' }}>
                <div className="uk-hidden@m" style={{ height: '4rem' }}></div>
                <div className="uk-animation-slide-top- uk-text-center uk-margin-xlarge-top">
                  <h1 className="uk-h1 uk-width-2xlarge uk-margin-auto">
                    <strong>{content.title.replace(/-/g, '\u2011')}</strong>
                  </h1>
                  <p className="uk-text-lead uk-width-2xlarge uk-margin-auto">{content.description}</p>
                  <a className="uk-button uk-button-primary uk-margin-top" href="/contact">
                    {t('Get Started')}
                  </a>
                  <div className="uk-margin-large-top">
                    {heroImg && <GatsbyImage image={heroImg} alt="hero image" />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Hero Section */}

      <section className="uk-section tm-products">
        <div className="uk-container">
          <div className="uk-text-center">
            <h2 className="uk-text-primary uk-width-2xlarge uk-margin-auto">{content.products.title}</h2>
          </div>
          <div
            className="uk-margin-large-top uk-grid-medium uk-child-width-1-3@m uk-grid-match  uk-flex-center"
            uk-grid=""
          >
            <div>
              <div
                className="uk-card uk-card-body uk-card-default uk-text-center"
                onClick={() => navigate('/products/jascloud')}
                style={{ cursor: 'pointer' }}
              >
                {JascloudImg && <GatsbyImage image={JascloudImg} alt="jascloud figure" />}
                <h3 className="uk-card-title">{content.products.jascloud.title}</h3>
                <p>{content.products.jascloud.description}</p>
                <Link to="/products/jascloud">{t('See More')} JASCLOUD</Link>
              </div>
            </div>

            <div>
              <div
                className="uk-card uk-card-body uk-card-default uk-text-center"
                onClick={() => navigate('/products/jasmeet')}
                style={{ cursor: 'pointer' }}
              >
                {JasmeetImg && <GatsbyImage image={JasmeetImg} alt="jasmeet figure" />}
                <h3 className="uk-card-title">{content.products.jasmeet.title}</h3>
                <p>{content.products.jasmeet.description}</p>
                <Link to="/products/jasmeet">{t('See More')} JASMEET</Link>
              </div>
            </div>

            <div>
              <div
                className="uk-card uk-card-body uk-card-default uk-text-center"
                onClick={() => navigate('/products/tos-omnichannel')}
                style={{ cursor: 'pointer' }}
              >
                {OmnichannelImg && <GatsbyImage image={OmnichannelImg} alt="omnichannel figure" />}
                <h3 className="uk-card-title">{content.products.omnichannel.title}</h3>
                <p>{content.products.omnichannel.description}</p>
                <Link to="/products/tos-omnichannel">{t('See More')} TOSCHAT</Link>
              </div>
            </div>

            <div>
              <div
                className="uk-card uk-card-body uk-card-default uk-text-center"
                onClick={() => navigate('/products/emergency-call')}
                style={{ cursor: 'pointer' }}
              >
                {SaktiImg && <GatsbyImage image={SaktiImg} alt="sakti figure" />}
                <h3 className="uk-card-title">{content.products.sakti.title}</h3>
                <p>{content.products.sakti.description}</p>
                <Link to="/products/emergency-call">{t('See More')} SAKTI 112</Link>
              </div>
            </div>

            <div>
              <div
                className="uk-card uk-card-body uk-card-default uk-text-center"
                onClick={() => navigate('/products/jasgrid')}
                style={{ cursor: 'pointer' }}
              >
                {JasgridImg && <GatsbyImage image={JasgridImg} alt="jasgrid figure" />}
                <h3 className="uk-card-title">{content.products.jasgrid.title}</h3>
                <p>{content.products.jasgrid.description}</p>
                <Link to="/products/jasgrid">{t('See More')} JASGRID</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Start Why Us Section */}
      <section className="uk-section" style={{ backgroundColor: 'aliceblue' }}>
        <div className="uk-container">
          <div uk-grid="">
            <div className="uk-width-expand@m uk-flex uk-flex uk-flex-center uk-flex-top uk-flex-last@m">
              <img
                src={figureUnifiedCommunication.publicURL}
                width="100%"
                alt=""
                uk-img=""
                style={{ aspectRatio: 'auto 542/491' }}
              />
            </div>
            <div className="uk-width-1-2@m uk-grid-item-match uk-flex-middle">
              <div className="uk-panel">
                <div uk-scrollspy="target: > *; cls: uk-animation-slide-left-small; delay: 200">
                  <h1 className="uk-text-capitalize">{content.why_us.title}</h1>
                  <dl className="uk-description-list who-is-list">
                    {content.why_us.lists.map(({ title, description }: any, index: number) => (
                      <>
                        <dt>{title}</dt>
                        <dd>{description}</dd>
                      </>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Why Us Section */}

      {/* Start Our Client Section */}
      <section className="uk-section-large">
        <div className="uk-container">
          <div className="" uk-scrollspy="target: > *; cls: uk-animation-slide-right-small; delay: 100">
            <div className="uk-width-xlarge@m uk-margin-auto">
              <h1 className="uk-text-center">{content.our_clients.title}</h1>
            </div>

            <h3 className="uk-text-center uk-text-left@m">{content.our_clients.government_institutions}</h3>
            <div className="uk-child-width-1-3 uk-flex-center uk-child-width-1-5@m" uk-grid="">
              {clientsGovernment.nodes.map((src, index) => (
                <img
                  key={index}
                  uk-img={`data-src: ${src.publicURL}`}
                  width="100%"
                  style={{ aspectRatio: 'auto 16/7' }}
                ></img>
              ))}
            </div>

            <h3 className="uk-text-center uk-text-left@m">{content.our_clients.enterprise_and_businesses}</h3>
            <div className="uk-child-width-1-3 uk-flex-center uk-child-width-1-5@m" uk-grid="">
              {clientsCompany.nodes.map((src, index) => (
                <img
                  key={index}
                  uk-img={`data-src: ${src.publicURL}`}
                  width="100%"
                  style={{ aspectRatio: 'auto 16/7' }}
                ></img>
              ))}
            </div>
          </div>
        </div>
      </section>
      {/* End Our Client Section */}

      <section>
        <div className="uk-container">
          <div className="" uk-scrollspy="target: > *; cls: uk-animation-slide-right-small; delay: 100">
            <div className="uk-width-xlarge@m uk-margin-auto">
              <h1 className="uk-text-center">Latest News</h1>
            </div>

            <div
              className="uk-position-relative uk-margin-medium-top uk-visible-toggle tm-study-case-slider"
              tabIndex={-1}
              uk-height-match="target: > ul > li > .uk-card"
              uk-slider="autoplay: true; autoplay-interval: 3000"
            >
              <div className="uk-slider-items uk-child-width-1-1 uk-child-width-1-3@s uk-child-width-1-4@m uk-grid">
                {allNews.edges.map(
                  ({ node: { id, title, updated_at, created_at, remoteImage, languages } }, index: number) => {
                    const img = getImage(remoteImage)
                    return (
                      <Link className="tm-disabled-link-decoration" key={id} to={generateSlug(id, title, created_at)}>
                        <div className="uk-card uk-card-small uk-card-body uk-card-hover uk-flex uk-flex-column">
                          <div>{img && <GatsbyImage image={img} alt={title} style={{ borderRadius: '1rem' }} />}</div>
                          <div className="uk-align-center">
                            <div
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '2',
                                WebkitBoxOrient: 'vertical',
                              }}
                            >
                              {languages.length > 0 ? (
                                languages.map((translation) => {
                                  if (translation.locale === i18n.language && translation.column_name === `title`) {
                                    {
                                      return <h4>{translation.value}</h4>
                                    }
                                  }
                                })
                              ) : (
                                <h4>{title}</h4>
                              )}
                            </div>
                            <p>
                              <small>{updated_at}</small>
                            </p>
                          </div>
                        </div>
                      </Link>
                    )
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["links", "products"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    figureHero: file(relativePath: { eq: "images/homepage-hero-background-element.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1080)
      }
    }
    figureCloudCommunication: file(relativePath: { eq: "images/figure-cloud-communication.svg" }) {
      id
      publicURL
    }
    figureUnifiedCommunication: file(relativePath: { eq: "images/figure-unified-communication.svg" }) {
      id
      publicURL
    }
    figureSmartCity: file(relativePath: { eq: "images/figure-smart-city.svg" }) {
      id
      publicURL
    }
    clientsCompany: allFile(filter: { relativePath: { glob: "images/clients/company-*" } }) {
      nodes {
        publicURL
      }
    }
    clientsGovernment: allFile(filter: { relativePath: { glob: "images/clients/government-*" } }) {
      nodes {
        publicURL
      }
    }
    figureOmnichannel: file(relativePath: { eq: "images/figure-omnichannel.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200)
      }
    }
    figureJascloud: file(relativePath: { eq: "images/figure-jascloud.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200)
      }
    }
    figureSakti: file(relativePath: { eq: "images/figure-sakti-112.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200)
      }
    }
    figureJasmeet: file(relativePath: { eq: "images/figure-jasmeet.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200)
      }
    }
    figureJasgrid: file(relativePath: { eq: "images/figure-jasgrid.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200)
      }
    }
    allNews(limit: 5) {
      edges {
        node {
          id
          title
          remoteImage {
            childImageSharp {
              gatsbyImageData(quality: 70, aspectRatio: 1.5)
            }
          }
          languages {
            locale
            column_name
            value
            desc
          }
          updated_at(fromNow: true, locale: $language)
          created_at
        }
      }
    }
  }
`
